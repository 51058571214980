import { SiteInfo } from '../../../interfaces/ISiteInfo';

export const sites: SiteInfo[] = [
    {
        name: 'NHS waiting list tracker',
        image: '/images/main/products/waiting-list-bn.png',
        description:
      'Explore our interactive tracker to see how waiting lists for NHS services vary across England.',
        allowed: true,
        permissionType: 'public',
        url: 'https://nhswaitlist.lcp.uk.com/',
    },
    {
        name: 'ONS Health Index Explorer',
        image: '/images/main/products/health-index-bn.png',
        description:
      'See the health index in your area, how it has changed over time and compares to other local authorities.',
        allowed: true,
        permissionType: 'public',
        url: 'https://healthindex.lcp.uk.com/',
    },
    {
        name: 'T2DM Comorbidity Explorer',
        image: '/images/main/products/dm-comorbidity-bn.png',
        description:
      'Examine the comorbidity profiles of patients with T2DM and the impact on resource and costs from our work with the National Institute for Health and Care Excellence (NICE).',
        allowed: true,
        permissionType: 'public',
        url: 'https://dmcomorbidities.lcp.uk.com/',
    },
    {
        name: 'Health & Wealth Explorer',
        image: '/images/main/products/health-wealth-bn.png',
        description:
      'Explore and compare key statistics for local authorities in England, including health, education and employment.',
        allowed: false,
        permissionType: 'public',
        url: 'https://healthandwealth.lcp.uk.com/',
    },
];
