import { Http } from '@lcp/http-auth';

export interface PostItem {
    PageTitle: string;
    PageUrl: string;
    ImageUrl: string;
    Summary: string;
    AuthorName: string;
    PublicationDate: string;
    JobTitle: string;
}

export interface BlogResponseDTO {
    ListItems : Array<PostItem | {
        [key: string] : string | boolean | unknown[];
    }>
    CurrentPage: number;
    TotalPages: number;
    Url: string;
    UrlAbsolute : string;
    NoResultsMessage: string;
    ListParams: {
        [key: string] : unknown
    }
}

export const dummyData : BlogResponseDTO = {
    ListItems: [
        {
            PageTitle: 'A&E waiting times already as bad as last year with Covid and Flu about to add to existing pressure',
            PageUrl: 'https://www.lcp.com/our-viewpoint/2023/12/ae-waiting-times-already-as-bad-as-last-year-with-covid-and-flu-about-to-add-to-existing-pressure',
            ImageUrl: '/media/1145220/lcp_ben_rees_2018_screen.jpg?rxy=0.5819672131147541,0.4426229508196721&width=426&height=414&v=1d9fc508d90ca80',
            Summary: 'In this blog Stuart McDonald, Ben Rees and Florence Ma analyse what NHS data is saying about A&E pressure.',
            AuthorName: 'Ben Rees',
            JobTitle: 'Principal',
            AuthorUrl: 'https://www.lcp.uk.com/our-experts/j/jonathan-pearson-stuttard/',
            PublicationDate: '2023-12-15T00:00:00',
            HasVideo: false,
            DocumentType: 'blogArticleContentPage',
            IsHidden: false,
            Authors: [
                {
                    Name: 'Jonathan Pearson-Stuttard',
                    JobTitle: 'Partner & Head of Health Analytics',
                    ImageUrl: '/media/1145220/lcp_ben_rees_2018_screen.jpg?rxy=0.5819672131147541,0.4426229508196721&width=426&height=414&v=1d9fc508d90ca80',
                    PageUrl: 'https://www.lcp.uk.com/our-experts/j/jonathan-pearson-stuttard/',
                },
            ],
        },
        {
            PageTitle: 'LCP partners lead first study to provide granular insights into the causes of post-pandemic excess deaths    ',
            PageUrl: 'https://www.lcp.com/our-viewpoint/2023/12/lcp-partners-lead-first-study-to-provide-granular-insights-into-the-causes-of-post-pandemic-excess-deaths',
            ImageUrl: '/media/1149586/stuart-mcdonald.png?width=426&height=414&v=1d9fc5093333450',
            Summary: 'Stuart McDonald provides insights into the causes of excess deaths that occurred after the pandemic and how this impacts DB pension schemes.',
            AuthorName: 'Stuart McDonald',
            JobTitle: 'Partner',
            AuthorUrl: 'https://www.lcp.uk.com/our-experts/g/grace-mitchell/',
            PublicationDate: '2023-12-07T00:00:00',
            HasVideo: false,
            DocumentType: 'blogArticleContentPage',
            IsHidden: false,
            Authors: [
                {
                    Name: 'Grace Mitchell',
                    JobTitle: 'Market Access Specialist',
                    ImageUrl: 'https://www.lcp.uk.com/media/1149038/grace-mitchell.png?anchor=center&mode=crop&width=190&height=185&rnd=133053837180000000',
                    PageUrl: 'https://www.lcp.uk.com/our-experts/g/grace-mitchell/',
                },
            ],
        },
        {
            PageTitle: 'Webinar with ISPOR Applications of Large Language Models for RWD Analytics',
            PageUrl: 'https://www.lcp.com/our-viewpoint/2023/11/navigating-the-nhs-what-life-sciences-should-consider-as-a-general-election-approaches',
            ImageUrl: '/media/1150082/maisie-borrows.jpg?width=426&height=414&v=1d9fc50912ae130',
            Summary: 'In this blog, Maisie Borrows in LCP Health Analytics will touch upon the various issues facing the UK health system in the wake of the upcoming elections.',
            AuthorName: 'Maisie Borrows',
            JobTitle: 'Strategic Growth Lead ',
            AuthorUrl: 'https://www.lcp.uk.com/our-experts/a/alex-castanon/',
            PublicationDate: '2023-11-22T00:00:00',
            HasVideo: false,
            DocumentType: 'blogArticleContentPage',
            IsHidden: false,
            Authors: [
                {
                    Name: 'Alex Castanon',
                    JobTitle: 'Consultant ',
                    ImageUrl: 'https://www.lcp.uk.com/media/1149200/alex-castanon.png?anchor=center&mode=crop&width=190&height=185&rnd=133072129060000000',
                    PageUrl: 'https://www.lcp.uk.com/our-experts/a/alex-castanon/',
                },
            ],
        },
        {
            PageTitle: 'Webinar with ISPOR Applications of Large Language Models for RWD Analytics',
            PageUrl: 'https://www.lcp.com/our-viewpoint/2023/10/webinar-with-ispor-applications-of-large-language-models-for-rwd-analytics',
            ImageUrl: '/media/1148518/ben-bray-screen-2021.jpg?width=426&height=414&v=1d9fc508d8d4810',
            Summary: 'Watch the webinar to inform your Real-World Evidence (#RWE) generation by harnessing the power of advanced analytics.',
            AuthorName: 'Ben Bray',
            JobTitle: 'Partner',
            AuthorUrl: 'https://www.lcp.uk.com/our-experts/j/jonathan-pearson-stuttard/',
            PublicationDate: '2023-10-10T00:00:00',
            HasVideo: false,
            DocumentType: 'blogArticleContentPage',
            IsHidden: false,
            Authors: [
                {
                    Name: 'Jonathan Pearson-Stuttard',
                    JobTitle: 'Partner & Head of Health Analytics',
                    ImageUrl: 'https://www.lcp.uk.com/media/1148288/microsoftteams-image-10.png?anchor=center&mode=crop&width=190&height=185&rnd=133053837190000000',
                    PageUrl: 'https://www.lcp.uk.com/our-experts/j/jonathan-pearson-stuttard/',
                },
            ],
        },
    ],
    CurrentPage: 1,
    TotalPages: 8,
    Url: '/our-viewpoint/',
    UrlAbsolute: 'https://www.lcp.uk.com/our-viewpoint/',
    NoResultsMessage: "Sorry, we couldn't find any viewpoint articles",
    ListParams: {
        Specialism: 9713,
        Location: 0,
        Accordion: 0,
        Name: 0,
        Level: 0,
        Solution: 0,
        Type: 0,
        DocumentType: '',
        Topic: 0,
        Month: null,
        Page: 1,
        PageSize: 4,
        Author: 0,
        EventType: 0,
        IsBulletins: false,
        OrderByDesc: true,
        OrderBy: 'pubdate',
    },
};

export async function fetchPosts (specialism?: number, type?: number, pageSize?: number, page?: number) : Promise<BlogResponseDTO> {
    return (await Http.get<BlogResponseDTO>(`lists/GetPagedViewpoints?specialism=${specialism}&type=${type}&pagesize=${pageSize}&page=${page}`)).data;
}
