import { createApp } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { Http } from '@lcp/http-auth';
import App from './App.vue';
import store from './store';

Http.initialise({
    baseUrl: process.env.VUE_APP_API_URL,
    allowCaching: true,
    cacheTimeout: Number.MAX_SAFE_INTEGER,
    disableAuth: true,
    defaultHeaders: {
        Accept: 'application/json, text/plain, */*',
        AcceptEncoding: 'gzip, deflate, br',
        AcceptLanguage: 'en-US,en;q=0.9',
        Origin: 'http://localhost:8080',
    },
});
console.log(process.env);

// library.add(
//     far,
// );

createApp(App).use(store).component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');
